.styled-input-container {
  width: 55%;
  margin-top: 1%;
}

.styled-input-container .MuiFormControl-root {
  display: flex !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}