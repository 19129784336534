.edit-associate-container {
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: center;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 25px;
}

.edit-associate-container h1 {
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 13px;
}
.edit-associate-container h2 {
  font-family: 'Roboto', sans-serif;
  font: normal 15pt Roboto;
  margin: 5vh;
}

.edit-associate-text-field {
  padding: 15px;
  margin: 15px;
  display: grid;
  grid-column-gap: 70px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding-left: 15%;
  padding-right: 15%;
}

.edit-associate-title {
  background-color: var(--button);
  color: var(--fontColor1);
}

.edit-associate-text-margin {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.return-adm-register {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-self: start;
  align-items: flex-start;
  justify-content: flex-start;
}
.return-adm-register a {
  color: var(--buttonBlue);
  display: flex;
  font-weight: bold;
  align-items: center;
  text-decoration: none;
  font-family: 'Roboto';
}

.return-adm-register a span {
  margin-right: 8px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-self: start;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url('../../images/arrow-left.svg');
  width: 22px;
  height: 22px;
  margin-bottom: 2px;
  font-family: 'Roboto';
}

@media screen and (max-width: 420px) {
  .edit-associate-text-field {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }

  .edit-associate-text-margin {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
  }
}
