.intranet-home-menu h1 {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  margin-bottom: 0.8%;
  color: var(--secondary);
  font-weight: 700;
  font-size: 2em;
}

.line-table-intranet-home-adm-menu {
  width: 100%;
  border: 2px solid;
  margin-bottom: 2%;
  color: var(--secondary);
}

.intranet-home-all {
  width: 80%;
}

@media (max-width: 540px) {
  .intranet-home-menu h1 {
    font-size: 1.3em;
  }
}

@media (max-width: 1300px) {
  .intranet-home-all {
    width: 90%;
  }
}

@media (max-width: 680px) {
  .intranet-home-all {
    width: 100%;
  }
}