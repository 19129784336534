.report-quizzes {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.container-report-quizzes {
    display: flex;
    width: 60%;
    min-width: 350px;
    height: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: rgb(243, 243, 243);
    border-style: solid;
    border-top-width: 20px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    text-align: center;
}

@media (max-width: 281px) {
    .container-report-quizzes {
      min-width: 250px;
    }
  }

.title-report-quizzes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    background-color: #e4e4e4;
    color:#000000;
    text-align: center;
    height: 25px;
    border-color: rgb(206, 206, 206);
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
}

.alltitlequiz-report-quizzes{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1.5%;
}

.titlequiz-report-quizzes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-self: flex-start;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    background-color: var(--button);
    color:#ffffff;
    text-align: center;
    width: 17%;
    margin-right: 0.5%;
    padding-top: 0.8%;
    padding-bottom: 0.8%;
    min-height: 25px;
    min-width: 50px;
}

.titleboxquiz-report-quizzes{
    display: flex;
    width: 100%;
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-color: rgb(235, 235, 235);
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    text-align: left;
    padding-left: 0.5%;
    padding-right: 0.5%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.descriptionquiz-report-quizzes {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    background-color: var(--button);
    color:#ffffff;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-around;
}

.descriptionboxquiz-report-quizzes{
    display: flex;
    width: 100%;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: rgb(235, 235, 235);
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-family: 'Roboto', sans-serif;
    
}

.chart-report-quizzes {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    background-color: var(--button);
    color:#ffffff;
    height: 30px;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}

.content-report-quizzes {
    display: flex;
    width: 100%;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: rgb(235, 235, 235);
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.content-alternatives-quizzes {
    display: flex;
    width: 100%;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: rgb(235, 235, 235);
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}