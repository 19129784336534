.container-print-associates-page {
  min-height: calc(100vh - 146px);
  display: flex;
  flex-direction: column;
}

.header-print-associates-icon {
  display: flex;
  align-items: center;
  background-color: var(--header);
  height: 50px;
}

.header-print-associates-icon button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: thin solid transparent;
  color: var(--fontColor1);
  font-size: 17px;
  cursor: pointer;
  padding: 0 3px;
  height: 100%;
}

.header-print-associates-icon button:hover {
  background-color: var(--hover);
  border: thin solid var(--hover);
  border-radius: 5px;
}

.print-associates-button {
  margin-left: 20px;
}

.return-print-associates-button {
  margin-left: 60px;
}

.print-associates-table {
  margin: 5%;
}

.print-consult-associates-forms {
  table-layout: fixed;
  width: 100%;
  overflow-x: unset;
}