
@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@700&display=swap");

.container-forgot-password{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .container-forgot-password h1 {
    font-family: "Roboto", sans-serif;
    font: normal 18pt Roboto;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  
  }
  .container-forgot-password h2 {
    font-family: "Roboto", sans-serif;
    font: normal 10pt Roboto;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 5vh;
    margin-bottom: 0;
    font-weight: normal;
  }
  .campo-forgot-password {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
  }
  .box-forgot-password{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: auto;
    justify-content: center;
    background-color: var(--background, white);
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text-forgot-password{
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  .container-forgot-password img{
    margin-bottom: -3%;
    height: 40%;
    width: 90%;
}
  .container-forgot-password input {
    margin-bottom: 5%;
    margin-top: 1%;
    width: 85%;
    height: 8%;
    background-color: #ffffffab;
    box-sizing: border-box;
    border-radius: 6px;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .container-forgot-password button{
    background-color: var(--button);
    border: 1px solid var(--button);
    border-radius: 8px;
    color: white;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 90%;
    line-height: 20px;
    transition: filter 0.2s;
    margin-top:5%;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 10%;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
}
.container-forgot-password button:hover{
    background-color: #1f4061;
}
.outlined-basic {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
.voltar-forgot-password {
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: flex-start;
    justify-content: flex-start;
}
.voltar-forgot-password a{
    color:var(--buttonBlue);
    display: flex;
    font-weight: bold;
    align-items: center;
    text-decoration: none;
    font-family: "Roboto";
}

.voltar-forgot-password a span{
    margin-right: 8px;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: flex-start;
    justify-content: flex-start;
    background-image: url("../../images/arrow-left.svg");
    width: 22px;
    height: 22px;
    margin-bottom: 2px;
    font-family: "Roboto";
}

@media(max-width: 840px) {
    .box-forgot-password{
      width: 80%;
    }
    .container-forgot-password input {
        height: 8%;
      }
    .container-forgot-password h2 {
        font: normal 9pt Roboto;
    }

  }

@media(max-width: 634px) {
  .container-forgot-password button {
    align-items: center;
    justify-content: center;
    width: 38%;
    height: 16%;
  }
  .container-forgot-password input {
    height: 10%;
  }
  .container-forgot-password h1 {
    font: normal 15pt Roboto;
  }
  h2{
    margin: 1vh;
    margin-top: 3vh;
  }
}
@media(max-width: 353px) {
    .container-forgot-password input {
        height: 23px;
      }
    .container-forgot-password h2 {
        align-items: center;
        justify-content: center;
        font: normal 8pt Roboto;
    }
    .container-forgot-password button {
        font-size: 75%;
        height: 21%;
        width: 40%;
    }
    .span {
        margin-left: 15px;
    }
}

@media(max-width: 353px) {
    .voltar-forgot-password a span{
        margin-left: 20px;
    }
}
