@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@700&display=swap");

.news-description-field {
  align-items: center;
  width: 70%;
  margin-left: 15%;
  margin: calc(3% - 8px) 0 3% 15%;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.news-description-field h3 {
  font-size: 17px;
  font-weight: 100;
  margin-bottom: 1%;
  color: var(--color2)
}