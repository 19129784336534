@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@700&display=swap");

.body-quizzes-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-quizzes {
  display: flex;
  width: 100%;
  width: 100%;
  margin-bottom: 1.5%;
  box-shadow: 0 8px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
}

.title-card-quizzes {
  background-color: transparent;
  text-align: center;
  padding: 15px;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  cursor: pointer;
}

.tagg-status-quizz{
  position: absolute;
  margin-left: 53%;
}

.title-card-quizzes p {
  font-family: 'Roboto', sans-serif;
  font-size: large;
  font-weight: 200;
  margin-right: 40%;
  margin-left: 2%;
  align-items: center;
  display: flex;
  color: var(--fontColor2);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: initial;
  word-break: break-all;
}

.description-card-quizzes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: var(--background);
  padding: 10px;
  ;
}

.description-card-quizzes p {
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  word-break: break-all;
  flex-wrap: wrap;
}

.form-content-vote-quizzes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.form-vote-quizz-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5% 0;
}

.form-vote-quizz-container h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: var(--fontColor2);
  padding: 10px;
  width: 100%;
  text-align: center;
}

.alternatives-vote-quizzes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
  width: 350px;
}

.alternatives-vote-quizzes button {
  background-color: var(--color4);
  border: thin solid var(--color4);
  color: var(--fontColor1);
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
}

.alternatives-vote-quizzes button:hover {
  background-color: var(--color2);
  color: var(--fontColor1);
  border-color: var(--color2);
}


.unavaible-result {
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.unavaible-result-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Roboto;
}

.unavaible-result-text span {
  margin-right: 3px;
  margin-top: 1px;
}

/* @media(max-width: 1316px){
  .tagg-status-quizz{
    margin-left: 38% ;
  }
  .title-card-quizzes p {
    margin-right: 45%;
  }
}

@media (max-width: 1075px) {
  .css-i4bv87-MuiSvgIcon-root {
    height: 1.4em !important;
    width: 1.4em !important;
  }
} */
@media(max-width: 1884px){
  .tagg-status-quizz{
    margin-left: 50% ;
  }
}

@media(max-width: 1780px){
  .tagg-status-quizz{
    margin-left: 48% ;
  }
}
@media(max-width: 1600px){
  .tagg-status-quizz{
    margin-left: 45% ;
  }
}
@media(max-width: 1460px){
  .tagg-status-quizz{
    margin-left: 42% ;
  }
}
@media(max-width: 1330px){
  .tagg-status-quizz{
    margin-left: 38% ;
  }
}
@media(max-width: 1316px){
  .tagg-status-quizz{
    margin-left: 38% ;
  }
  .title-card-quizzes p {
    margin-right: 45%;
  }
}
@media(max-width: 1194px){
  .tagg-status-quizz{
    margin-left: 34% ;
  }
}
@media (max-width: 1075px) {
  .css-i4bv87-MuiSvgIcon-root {
    height: 1.4em !important;
    width: 1.4em !important;
  }
}
@media(max-width: 989px){
  .tagg-status-quizz{
    margin-left: 28% ;
  }
}

@media(max-width: 926px){
  .tagg-status-quizz{
    margin-left: 24% ;
  }
}
/* @media(max-width: 862px){
  .tagg-status-quizz{
    margin-left: 20% ;
  }
} */
@media(max-width: 750px){
  .tagg-status-quizz{
    /* margin-left: 17%; */
    margin-left: 21%;
  }
}
@media(max-width: 680px){
  .tagg-status-quizz{
    margin-left: 53% ;
  }
  .title-card-quizzes p {
    margin-right: 45%;
  }
}

@media(max-width: 538px){
  .tagg-status-quizz{
    margin-left: 50% ;
  }
}

@media(max-width: 498px){
  .tagg-status-quizz{
    margin-left: 47% ;
  }
  .title-card-quizzes p{
    font-size: 1.3em;
  }
}
@media(max-width: 454px){
  .tagg-status-quizz{
    margin-left: 44% ;
  }
  .title-card-quizzes p{
    font-size: 1.3em;
  }
}

@media(max-width: 370px){
  .tagg-status-quizz{
    margin-left: 40% ;
  }
  .title-card-quizzes p{
    font-size: 1.2em;
  }
}

@media(max-width: 330px){
  .title-card-quizzes p{
    font-size: 0.9em;
  }
  .tagg-status-quizz{
    margin-left: 37% ;
  }
}
@media(max-width: 306px){
  .tagg-status-quizz{
    margin-left: 34% ;
  }
}
@media(max-width: 266px){
  .tagg-status-quizz{
    margin-left: 32% ;
  }
}

@media (max-width: 786px) {
  .alternatives-vote-quizzes button {
    max-width: 300px;
  }
}

@media (max-width: 450px) {
  .alternatives-vote-quizzes button {
    max-width: 250px;
  }
}

@media (max-width: 336px) {
  .alternatives-vote-quizzes {
    max-width: 200px;
  }
}