.initial-petitions-menu-title h1 {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font: normal 18pt Roboto;
    margin-bottom: 0.8%;
    color: var(--secondary);
    font-weight: 700;
    font-size: 2em;
}

.line-table-initial-petitions-menu {
  width: 100%;
  border: 2px solid;
  margin-bottom: 2%;
  color: var(--secondary);
}

.initial-petitions-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.initial-petitions-menu-container {
  height: 100%;
}

@media (max-width: 540px) {
  .initial-petitions-menu-title h1 {
    font-size: 1.3em;
  }
}