@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@700&display=swap");

.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.Box-login h1 {
  font-family: "Roboto", sans-serif;
  font: normal 15pt Roboto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.Box-login h2 {
  font-family: "Roboto", sans-serif;
  font: normal 10pt Roboto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 5vh;
  margin-bottom: 0;
  font-weight: normal;
}

.campo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}

.Box-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: auto;
  justify-content: center;
  background-color: var(--background);
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Box-login button p {
  color: white;
}

.text-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-login img {
  margin-bottom: -3%;
  height: 40%;
  width: 90%;
}

.container-login-input-username {
  margin-bottom: 3%;
  margin-top: 1%;
  width: 55%;
  height: 43px;
  background-color: var(--fontColor1);
  box-sizing: border-box;
  border-radius: 6px;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.container-login-button {
  background-color: var(--button);
  border: 1px solid var(--button);
  border-radius: 8px;
  color: white;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 90%;
  line-height: 20px;
  transition: filter 0.2s;
  margin-top: 0%;
  margin-bottom: 3%;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 8%;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.container-login-button:hover {
  background-color: var(--color1);
}

.outlined-basic {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Remember-Box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Remember-Box input {
  margin-top: 0px;
  height: 5.4vh;
  border: 1px solid var(--inputColor);
  border-radius: 8px;
  display: flex;
  text-align: center;
  width: 15px;
}

label {
  font-size: 12px;
  margin-top: -2.4px;
  padding-left: 5px;
  font-family: "Roboto", sans-serif;
}

.Link-ForgottenPassword {
  margin-top: 10px;
}

.Link-ForgottenPassword a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 0.9em;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.Link-ForgottenPassword a:hover {
  color: rgb(41, 41, 41);
}

@media (max-width: 1050px) {
  .Box-login {
    width: 70%;
  }
}

@media (max-width: 840px) {
  .Box-login {
    width: 70%;
  }
}

@media (max-width: 634px) {
  .container-login-button {
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 10%;
  }

  .Box-login h1 {
    font: normal 13pt Roboto;
  }

  .Link-ForgottenPassword a {
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 0.7em;
  }
}

@media (min-width: 1250px) {
  .container-login-input-username {
    height: 43px;
    font-size: 1rem;
  }
}

@media (max-width: 1250px) {
  .container-login-input-username {
    height: 37px;
    font-size: 0.95rem;
  }
}

@media (max-width: 690px) {
  .container-login-input-username {
    height: 34px;
    font-size: 0.9rem;
  }
}

@media (max-width: 570px) {
  .container-login-input-username {
    height: 31px;
    font-size: 0.85rem;
  }
}

@media (max-width: 353px) {
  .Box-login {
    width: 90%;
  }

  .container-login-button {
    font-size: 80%;
    height: 14%;
  }

  .Box-login h1 {
    font: normal 13pt Roboto;
  }
}