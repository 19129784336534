.consult-minutes-page {
  min-height: calc(100vh - 221px);
}

.container-consult-minutes {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 25px;
}

.loader-consult-minutes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 221px);
  font-size: 35px;
}

.title-consult-minutes h1 {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  margin-bottom: 0.8%;
  color: var(--secondary);
  font-weight: 700;
  font-size: 2em;
}

.consult-minutes-field {
  display: flex;
  width: 100%;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
}

.line-table-consult-minutes {
  width: 100%;
  border: 2px solid;
  margin-bottom: 2%;
  color: var(--secondary);
}