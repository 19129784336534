
.RemoveQuizzModal-RemoveGroup{
    display:flex;
    align-items:center;
    justify-content:center;
    font-family: Roboto;
    font-size: 100%;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    outline:none;    
    background-color: white;
  }

  .RemoveQuizzModal-ContainerModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 180px;
    width: 450px;
    border-radius: 6px;
    background-color: white;
    border: 0.5px solid black;
    box-shadow: 24;
    padding: 4;
  }

  .RemoveQuizzModal-text{
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
  }

  .RemoveQuizzModal-Question{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    font-family:Roboto;
    font-weight: normal;
    font-size: 20px;
    color: black;
    margin-bottom: 7%;
  }

  .RemoveQuizzModal-Buttons{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    flex-direction: row;
  }

  .RemoveQuizzModal-button1{
    margin-left: 13px;
  }

  .RemoveQuizzModal-button2{
    margin-right: 13px;
  }

  .RemoveQuizzModal-ButtonCancel{
    display:flex;
    height: 55px;
    width: 200px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    border: solid black;
    border-width: 1px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-bottom: 3%;
    background-color: var(--button);
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .RemoveQuizzModal-ButtonConfirm{
    display:flex;
    height: 55px;
    width: 200px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    border: solid black;
    border-width: 1px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-bottom: 3%;
    background-color: var(--button);
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .RemoveQuizzModal-align{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .RemoveQuizzModal-align p {
    color: white;
  }

  .RemoveQuizzModal-ButtonCancel:hover{
    background-color: var(--primary);
  }

  .RemoveQuizzModal-ButtonConfirm:hover{
    background-color: var(--primary);
  }

  @media(max-width: 685px){
    .RemoveQuizzModal-ContainerModal{
      height: 130px;
      width: 350px;
    }

    .RemoveQuizzModal-ButtonConfirm{
      height: 40px;
      width: 120px;
      font-size: 15px;
    }

    .RemoveQuizzModal-ButtonCancel{
      height: 40px;
      width: 120px;
      font-size: 15px;
    }

    .RemoveQuizzModal-button1{
      margin-left: 40px;
    }

    .RemoveQuizzModal-button2{
      margin-right: 40px;
    }

  .RemoveQuizzModal-Question{
    font-size: 16px;
  }

  }

  @media(max-width: 495px){
    .RemoveQuizzModal-RemoveGroup .css-i4bv87-MuiSvgIcon-root {
      width: 0.95em !important;
      height: 0.95em !important;
    }
  }

  @media(max-width: 370px){
    .RemoveQuizzModal-ContainerModal{
      height: 130px;
      width: 270px;
    }

    .RemoveQuizzModal-ButtonConfirm{
      height: 30px;
      width: 100px;
      font-size: 14px;
    }

    .RemoveQuizzModal-ButtonCancel{
      height: 30px;
      width: 100px;
      font-size: 14px;
    }

  .RemoveQuizzModal-Question{
    font-size: 14px;
  }

  .RemoveQuizzModal-button1{
    margin-left: 0;
  }

  .RemoveQuizzModal-button2{
    margin-right: 0;
  }

  .RemoveQuizzModal-Buttons{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    flex-direction: column;
  }

  }