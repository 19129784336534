.container-exclude-and-change-minutes {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
}

.title-exclude-and-change-minutes h1 {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  margin-bottom: 0.8%;
  color: var(--secondary);
  font-weight: 700;
  font-size: 2em;
}

.line-table-exclude-and-change-minutes {
  width: 100%;
  border: 2px solid;
  margin-bottom: 2%;
  color: var(--secondary);
}

@media (max-width: 540px) {
  .title-exclude-and-change-minutes h1 {
    font-size: 1.3em;
  }
}