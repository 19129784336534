
.register-comunic-container{
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 221px);
  justify-content: center;
  flex-direction: center;
  flex-wrap: wrap;
  text-align: center;

}

.register-comunic-container h1{
  font-family: "Roboto", sans-serif;
  font: normal 18pt Roboto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
}
.register-comunic-container h2 {
  font-family: "Roboto", sans-serif;
  font: normal 15pt Roboto;
  margin: 5vh;
}

.register-comunic-text-field{
  padding: 15px;
  margin: 15px;
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  padding-left: 15%;
  padding-right: 15%;

}

.register-comunic-title{
  background-color:#1C3854;
  color: #FFFFFF;
}

.register-comunic-text-margin{
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.register-comunic-align-test{
  font-family: "Roboto", sans-serif;
  font: normal 18pt Roboto;
  display:flex;
  align-items:center;
  justify-content:center;
}

@media screen and (max-width: 720px){
  .register-comunic-text-field{
    padding: 15px;
    margin: 15px;
    display: grid;
    grid-column-gap: 70px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    padding-left: 15%;
    padding-right: 15%;
  
  }
}


@media screen and (max-width: 420px){
  .register-comunic-text-field{
      padding: 0px;
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)); 

  }

  .register-comunic-text-margin{
      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 13px;
  }

  .register-comunic-button{
    width: 80%;
  }
}
