.containerBottomMenu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.boxBottomMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  width: 80%;
  box-shadow: 0 0 1em rgb(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.buttonBottomMenu {
  height: 40px;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin-bottom: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: var(--hover);
}

.buttonBottomMenu:hover {
  box-shadow: 0 0 1em rgb(0, 0, 0, 0.1);
}
.buttonSelectedBottomMenu {
  height: 40px;
  display: flex;
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin-bottom: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: var(--hover);
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0 8px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
}

.iconBottomMenu {
  margin-right: 20px;
}

.flagsBottomMenu {
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  width: 30%;
}

.flagsBottomMenu > img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  min-width: 40px;
  max-width: 80px;
  box-shadow: 0 0 0.2em rgb(0, 0, 0, 0.2);
}

@media (max-width: 670px) {
  .boxBottomMenu {
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    font-size: 10pt;
  }
  .buttonBottomMenu {
    padding: 0;
  }
  .buttonsBottomMenu {
    width: 80%;
  }
  .flagsBottomMenu {
    margin-top: 10px;
    width: 80%;
  }
}
