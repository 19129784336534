.titleAdministrationRecords {
    color: #2574A9;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-top:3%;
    margin-left: 40%;
    margin-right: 30%; 
}

.containerAdministrationRecords {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 25px 25px 25px;
}
.title-adm-register-notice h1 {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font: normal 18pt Roboto;
    margin-bottom: 0.8%;
    color: var(--secondary);
    font-weight: 700;
    font-size: 2em;
  }
  .consultNoticePageField{
    padding: 25px 25px 0 25px;
  }
  
  .line-table-adm-register-notice {
    width: 100%;
    border: 2px solid;
    margin-bottom: 2%;
    color: var(--secondary);
  }
  
  @media (max-width: 540px) {
    .title-adm-register-notice h1 {
      font-size: 1.3em;
    }
  }
