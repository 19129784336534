@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@700&display=swap");

.intranet-total-page-container {
  background-image: url(../../images/LogoPretoBranco.png);
  background-repeat: no-repeat;
  background-position: 400px 200px;
}

.intranet-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10%;
  min-height: calc(100vh - 221px);
}

.intranet-dashboad-all {
  padding: 75px 25px;
  width: 90%;
}

.intranet-side-menu {
  padding: 150px 0;
}

.intranet-welcome {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
  font-family: "Roboto", sans-serif;
  color: var(--primary);
  margin: 75px 13% 0 10%;
}

.intranet-welcome img {
  height: 75px;
}

.intranet-welcome h1 {
  font-size: 75px;
  font-weight: 200;
  margin-left: 10px;
}

.intranet-welcome h2 {
  font-weight: 200;
}

.intranet-rejufe-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TermsModal {
  font-weight: bold;
  cursor: pointer;
  
  text-decoration: underline;
  border: none;

  background: inherit;
  color: inherit;
}

.TermsModal span:hover{
  opacity: 0.8;
}

@media (max-width: 1200px) {
  .intranet-dashboad-all {
    padding: 75px 20px;
    width: 80%;
  }
}

@media (max-width: 1070px) {
  .intranet-total-page-container {
    background-position: 300px 200px;
  }
}

@media (max-width: 974px) {
  .intranet-total-page-container {
    background-position: 300px 200px;
  }
}

@media (max-width: 970px) {
  .intranet-dashboad-all {
    padding: 75px 0 0 20px;
    width: 75%;
  }
  .intranet-main-container {
    padding: 0 12% 0 10%;
  }
  .intranet-welcome {
    margin: 75px 70px 0 50px;
  }
  .intranet-welcome h1 {
    font-size: 55px;
  }
  .intranet-welcome img {
    height: 55px;
  }
  .intranet-total-page-container {
    background-position: 200px 200px;
  }
}

@media (max-width: 841px) {
  .intranet-total-page-container {
    background-position: 100px 200px;
  }
}

@media (max-width: 800px) {
  .intranet-dashboad-all {
    width: 70%;
  }
}

@media (max-width: 680px) {
  .intranet-main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .intranet-side-menu {
    padding: 50px 0;
  }

  .intranet-dashboad-all {
    padding: 2%;
    width: 90%;
  }
  .intranet-welcome {
    flex-direction: column;
    margin: 75px 0 0;
  }
  .intranet-rejufe-logo {
    margin-bottom: 20px;
  }
  .intranet-total-page-container {
    background-position: 45px 200px;
  }
}

@media (max-width: 400px) {
  .intranet-dashboad-all {
    padding: 0;
  }

  .intranet-main-container {
    padding: 0 5%;
  }
}
