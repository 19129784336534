.container-date-quizz-to-init{
  width: 150px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: var(--primary);
  border-radius: 8px;
}

.container-date-quizz-progress{
  width: 150px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #008000;
  border-radius: 8px;
}

.container-date-quizz-finish{
  width: 150px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #831d1c;
  border-radius: 8px;
}

 .container-date-quizz-to-init h5{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 200;
  word-break: break-all;
}

.container-date-quizz-finish h5{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 200;
  word-break: break-all;
}

.container-date-quizz-progress h5{
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 200;
  word-break: break-all;
}
@media(max-width: 989px){
  .container-date-quizz-to-init{
    width: 130px ;
  }
  .container-date-quizz-finish{
    width: 130px ;
  }
  .container-date-quizz-progress{
    width: 130px ;
    height: 30px;
  }
}


@media(max-width: 800px){
  .container-date-quizz-to-init{
    width: 100px ;
  }
  .container-date-quizz-finish{
    width: 100px ;
  }
  .container-date-quizz-progress{
    width: 100px ;
    height: auto;
  }
  .h5{
    font-size: 1em;
  }
}
@media(max-width: 800px){
  .container-date-quizz-to-init{
    width: 85px ;
  }
  .container-date-quizz-finish{
    width: 85px ;
  }
  .container-date-quizz-progress{
    width: 85px ;
  }
  .h5{
    font-size: 0.8em;
  }
}

@media(max-width: 383px){
  .container-date-quizz-to-init h5{
    font-size: 0.8em;
  }
  .container-date-quizz-finish h5{
    font-size: 0.8em;
  }
  .container-date-quizz-progress h5{
    font-size: 0.7em;
    height: 30px;
    padding:5px;
  }
}
@media(max-width: 383px){
  .container-date-quizz-to-init {
    width: 75px;
  }
  .container-date-quizz-finish {
    width: 75px;
  }
  .container-date-quizz-progress {
    width: 75px;
  }
}

