.linearColorContainer{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linearColorContainer p {
  color: var(--primary);
  font: 3em Roboto;
  text-align: center;
}

.loadingP:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: var(--primary);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 var(--primary),
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 var(--primary),
      .5em 0 0 var(--primary);}}


