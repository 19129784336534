.box-form-contactUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 15%;
  margin-left: 15%;
}

.box-form-contactUs input,
textarea {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.19);
  padding: 1%;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  resize: none;
}

.box-form-contactUs textarea {
  height: 10em;
}

.box-form-contactUs h1 {
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  margin-bottom: 0.8%;
  color: var(--secondary);
  font-weight: 700;
  font-size: 2em;
}

.line-table-contactUs {
  width: 100%;
  border: 2px solid;
  margin-bottom: 2%;
  color: var(--secondary);
}

.box-form-contactUs button {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-ContactUs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button-ContactUs button + button {
  margin-left: 10px;
}

.send-button-ContactUs {
  background-color: #2574A9;
}

@media (max-width: 480px) {
  .box-form-contactUs {
    margin: 0% 5%;
  }
}

