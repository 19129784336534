.consultAssociatePage {
    min-height: calc(100vh - 221px);
    display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-consult-associates h1 {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font: normal 18pt Roboto;
    margin-bottom: 0.8%;
    color: var(--secondary);
    font-weight: 700;
    font-size: 2em;
  }
  
  .line-table-consult-associates {
    width: 100%;
    border: 2px solid;
    margin-bottom: 2%;
    color: var(--secondary);
  }

.containerConsultAssociate {
    display: flex;
    width: 100%;
    height: 100%;
}

.loaderConsultAssociate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 221px);
    font-size: 35px;
}

.button-table-component-pagination-consult {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

@media (max-width: 540px) {
    .title-consult-associates h1 {
      font-size: 1.3em;
    }
  }
  
