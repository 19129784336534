.containerChangePassword {
  height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxChangePassword {
  font-family: 'Roboto', sans-serif;
  height: 60%;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  border-radius: 12px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  background-color: #F8F8F8;
  padding: 4%;
  text-align: justify;
}

.boxChangePassword button {
  padding: 2% 5%;
  margin-top: 4%;
  min-width: 100px;
  min-height: 50px;
  border-radius: 0.5vw;
  border: 1px solid;
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: white;
  font-size: 1.2em;
}

.change-password-text h1 {
  font-size: 2em;
  margin-bottom: 4%;
  font-weight: 100;
  color: var(--fontColor2);
}

.change-password-text p {
  font-size: 1.2em;
  margin: 0;
  font-weight: 100;
  color: var(--fontColor2);
}

.containerChangePassword button:hover {
  cursor: pointer;
}

.modalStyleChangePassword {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popUpChangePassword {
  font-family: 'Roboto', sans-serif;
  width: 45%;
  height: 30%;
  padding: 2%;
  background-color: white;
  color: black;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 12pt;
}

.popUpChangePassword h1 {
  font-size: 1.4em;
  margin-bottom: 10px;
  font-weight: 400;
}

.popUpChangePassword p {
  font-size: 1em;
}

.popUpChangePassword button {
  font-size: 1em;
  width: 8vw;
  height: 3vw;
  min-width: 80px;
  min-height: 40px;
  background-color: transparent;
  border: none;
  color: blue;
}

.popUpChangePassword button:hover{
  cursor: pointer;
}

.popUpChangePassword button + button {
  color: red;
}

.grupoButtonsChangePassword {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.change-passowrd-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 3%;
}

@media (min-width: 1500px) {
  .containerChangePassword{ 
    font-size: 16pt;
  }

  .popUpChangePassword {
    font-size: 16pt;
  }

  .popUpChangePassword button + button{
    margin-left: 5%;
  }
}

@media (max-width: 1050px) {
  .containerChangePassword{ 
    font-size: 10pt;
  }
  .popUpChangePassword {
    font-size: 10pt;
  }

}

@media (max-width: 715px){
  .boxChangePassword{
    width: 80%;
    height: 60%;
  }
  .modalStyleChangePassword {
    height: 100%;
    width: 100%;
  }
  .boxChangePassword button {
    border-radius: 6px;
  }
}

@media (max-width: 500px) {
  .boxChangePassword {
    height: 80%;
    padding: 10%;
    padding-top: 20%;
    padding-bottom: 20%;
    justify-content: center;
  }
  .popUpChangePassword {
    text-align: justify;
    padding: 8%;
    padding-top: 10%;
    padding-bottom: 10%;
    height: 50vh;
    width: 70vw;
  }
  .boxChangePassword button{
    margin-top: 35px;
  }
  .boxChangePassword h1{
    margin-bottom: 35px;
  }
}

@media (max-width: 395px) {
  .grupoButtonsChangePassword{
    justify-content: center;
  }

  .popUpChangePassword h1{
    font-size: 1.2em;
  }

}

