
.register-associate-container{
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: center;
    flex-wrap: wrap;
    text-align: center;

}

.register-associate-container h1{
    font-family: "Roboto", sans-serif;
    font: normal 18pt Roboto;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
}
.register-associate-container h2 {
    font-family: "Roboto", sans-serif;
    font: normal 15pt Roboto;
    margin: 5vh;
  }

.register-associate-text-field{
    padding: 15px;
    margin: 15px;
    display: grid;
    grid-column-gap: 70px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding-left: 15%;
    padding-right: 15%;

}

.register-associate-title{
    background-color:#1C3854;
    color: #FFFFFF;
}

.register-associate-text-margin{
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.Terms-checkbox-container label{
  font-size: 15px;
}

.Terms-checkbox-container label button{
  font-weight: bold;
  cursor: pointer;
  border: none;
  background: none;
}

.Terms-checkbox-container label button:hover{
  opacity: 0.8;
  text-decoration: underline;
}

@media screen and (max-width: 420px){
    .register-associate-text-field{
        padding: 0px;
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)); 

    }

    .register-associate-text-margin{
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 13px;
    }
}
