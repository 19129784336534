.form-user-module-page{
  max-width: 50%;
}

.container-cards-quizzes {
  height: 100%;
  width: 100%;
}

.division-cards-quizzes {
  height: 100%;
  width: 100%;
  padding-top: 2.5%;
}

.division-cards-quizzes h1 {
  font-family: 'Roboto', sans-serif;
  font: normal 18pt Roboto;
  margin-bottom: 0.8%;
  color: var(--secondary);
  font-weight: 700;
  font-size: 2em;
}

.line-table-cards-quizzes {
  width: 100%;
  background-color: var(--secondary);
  border: 2px solid;
  color: var(--secondary);
}

.title-cards-quizzes-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filter-create-cards-quizzes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2% 0;
}

.loader-cards-quizzes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 650px) {
  .intranet-main-container {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }

  .container-cards-quizzes {
    display: flex;
    justify-content: center;
    padding: 0 15px 0;
  }
}
@media (max-width: 540px) {
  .division-cards-quizzes h1 {
    font-size: 1.3em;
  }
}
@media(max-width: 509px){
  .title-cards-quizzes-page {
    flex-direction: column;
  }
  .title-cards-quizzes-page h1{
    text-align: center;
    margin-bottom: 10px;
  }
  .title-cards-quizzes-page {
    margin: 0;
    margin-bottom: 5px;

  }
  .form-user-module-page{
    width: 70%;
  }
}