* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
}
html,
body,
div#root {
    height: 100%;
}

:root {
    --primary: #264A6F;
    --secondary: #2F5C88;
    --button: #1C3854;
    --hover: #122438;
    --color1: #0B162B;
    --header: #081120;
    --color2: #3A404C;
    --color3: #616670;
    --color4: #81848C;
    --background: rgba(255, 255, 255, 0.6);
    --fontColor1: white;
    --fontColor2: black;
    --backgroundInput: #F0F0F5;
}

.quill > .ql-toolbar:first-child {
    display: none !important;
}