.title-list-comunic h1 {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font: normal 18pt Roboto;
    margin-bottom: 0.8%;
    color: var(--secondary);
    font-weight: 700;
    font-size: 2em;
}

.container-list-comunic {
    width: 100%;
    border: 2px solid;
    margin-bottom: 2%;
    color: var(--secondary);
  }

@media (max-width: 540px) {
    .title-list-comunic h1{
      font-size: 1.3em;
    }
  }
